/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:601d0138-0f59-4c62-89c9-a795e636665e",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_GFAp0SDxh",
    "aws_user_pools_web_client_id": "1rnu18aka8gk8k9hrjlttg40cs",
    "oauth": {
        "domain": "mychessrepertoireui41ef16a1-41ef16a1-prod.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://mychessrepertoire.com/repertoires",
        "redirectSignOut": "https://mychessrepertoire.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "mychessrepertoireui-20220831014256-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d1kj4ya9szqpn3.cloudfront.net"
};


export default awsmobile;
